import Vue from 'vue';
import store from '@/store';
import Vconsole from 'vconsole';
var hostName = window.location.hostname;
var host = hostName.split('.')[0];
var isWhiteList = (hostName === '47.98.46.218') || (hostName === '47.97.160.180') || (hostName === 'web.chaolu.com.cn')
var hostArr = ['wx', 'wxs', 'app', 'apps', 'coach'];
const urlList = {
    pro: {
        baseURL: 'https://wxs.chaolu.com.cn',
        baseURLApp: 'https://app.chaolu.com.cn',
        locationUrl: 'https://web.chaolu.com.cn'
    },
    dark: {
        baseURL: 'http://wxdark.chaolu.com.cn',
        baseURLApp: 'https://app.chaolu.com.cn',
        // baseURLApp : 'https://app.chaolu.com.cn',
        locationUrl: 'http://webdark.chaolu.com.cn'
    },
    test: {
        baseURL: 'http://wxtest.chaolu.com.cn:80',
        baseURLApp: 'http://apptest.chaolu.com.cn',
        // baseURLApp: 'http://192.168.6.206:9013',
        locationUrl: window.location.origin
    },
    beta: {
        baseURL: 'http://wxbeta.chaolu.com.cn:80',
        baseURLApp: 'http://appbeta.chaolu.com.cn',
        locationUrl: window.location.origin
    },
    dev: {
        baseURL: 'http://wxdev.chaolu.com.cn',
        baseURLApp: 'http://appdev.chaolu.com.cn',
        locationUrl: 'http://webdev.chaolu.com.cn'
    }
}
let urlJson = {};
if (hostArr.includes(host) || isWhiteList) {
    urlJson = urlList.pro;
    Vue.prototype.isTestEnvironment = false;
} else {
    if (hostName === 'webdark.chaolu.com.cn') {
        urlJson = urlList.dark;
    } else {
        urlJson = urlList.test;
    }
    // if(!process.env.VUE_APP_MODE=='wxg')
    new Vconsole();
    Vue.prototype.isTestEnvironment = true;
}
// if (window.location.href.indexOf('invite-gift') > -1) {
//   new Vconsole();
// }
if(store.state.isdev){
    urlJson=urlList.dev;
    //连正式
    // urlJson=urlList.pro;
    // store.commit('setUserId',742168);
    // store.commit('setToken','51tzuFQsTFI4G2Zhmdh');
}
Vue.prototype.baseURL = urlJson.baseURL;
Vue.prototype.baseURLApp = urlJson.baseURLApp;
Vue.prototype.locationUrl = urlJson.locationUrl;
